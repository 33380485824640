import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { HeadbotRoute } from "../../../services/Constants/HeadbotRoute";
import { CaptureQueryParamInSessionStorage } from "../../atoms/CaptureQueryParamInSessionStorage/CaptureQueryParamInSessionStorage";
import { QueryParamCaptureKey } from "../../atoms/CaptureQueryParamInSessionStorage/QueryParamCaptureKey";
import { HeroSection } from "../../molecules/HeroSection/HeroSection";
import { SampleAnimeGallery } from "../../molecules/SampleGallery/SampleAnimeGallery";
import { ScrollablePageTemplate } from "../../templates/ScrollablePageTemplate/ScrollablePageTemplate";

export const AnimePage: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    const description = t("animePage.description");
    const title = t("animePage.title");
    const features = [
        {
            name: t("animePage.valueProp.benefit1"),
        },
        {
            name: t("animePage.valueProp.benefit2"),
        },
        {
            name: t("animePage.valueProp.benefit3"),
        },
        {
            name: t("animePage.valueProp.benefit4"),
        },
    ];

    const featureImage = (
        <StaticImage
            className="mx-auto"
            src="./obama-anime.jpg"
            alt={t("animePage.heroImageAlt")}
            transformOptions={{ fit: "cover" }}
            width={512}
            objectPosition="50% 10%"
            style={{ borderRadius: "4%", overflow: "hidden" }}
            imgStyle={{ borderRadius: "4%" }}
        />
    );
    const heroTitle = t("animePage.hero.title");
    const ctaLabel = t("animePage.create");
    return (
        <ScrollablePageTemplate title={title} description={description} canonical={HeadbotRoute.Naruto}>
            <CaptureQueryParamInSessionStorage queryParamKey={QueryParamCaptureKey.Coupon} />
            <HeroSection
                features={features}
                featureImage={featureImage}
                ctaLabel={ctaLabel}
                title={heroTitle}
                ctaLink={HeadbotRoute.CreateNaruto}
            />
            <SampleAnimeGallery />
        </ScrollablePageTemplate>
    );
};
